import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import useAppNavigation from '@utils/hooks/useAppNavigation';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as SearchIcon } from '@icons/wolfkit-light/search-light.svg';
import SearchInput from '@shared/ui/inputs/SearchInput';
import { LIST_LOADING_HEIGHT, LoaderContainer, getSearchResultItems, } from '../../../lib';
import useTopbarSearch from '../../../api/useTopbarSearch';
const TopbarSearch = () => {
    const { t } = useTranslation();
    const { status, showMoreState, result, handleToggle: toggleList, } = useTopbarSearch();
    const { navigate, routes } = useAppNavigation();
    const handleToggle = (fieldKey) => () => toggleList(fieldKey);
    const goToProfile = (profile) => {
        navigate(routes.PROFILE, { params: { nickname: String(profile.username) } });
    };
    // const goToPortfolio = (portfolio) => {
    // }
    const searchResults = useMemo(() => {
        if (status === 'loading') {
            return [
                {
                    itemKey: 'loader',
                    children: (_jsx(LoaderContainer, { children: _jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }) })),
                    disableHover: true,
                    disableActive: true,
                    itemHeight: LIST_LOADING_HEIGHT,
                },
            ];
        }
        const { portfolios, traders, clients } = getSearchResultItems({
            result,
            handleToggle,
            showMoreState,
            handleClientClick: goToProfile,
            handleTraderClick: goToProfile,
            // handlePortfolioClick: goToPortfolio,
        });
        return [
            ...portfolios,
            ...traders,
            ...clients,
        ];
    }, [status, showMoreState, result]);
    const searchFn = () => { };
    return (_jsx(SearchInput, { startAdornment: (_jsx(Icon, { size: 16, IconComponent: SearchIcon })), menuHeight: 720, role: 'searchbox', placeholder: t('navigation.search.placeholder', { ns: 'common' }), menuItems: searchResults, zIndex: 1, hideMenu: false, maxCount: 12, filled: true, fullWidth: true }));
};
export default TopbarSearch;
