import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { CLIENT_ITEM_HEIGHT, ClientItemResult, PORTFOLIO_ITEM_HEIGHT, PortfolioItemResult, SECTION_TITLE_HEIGHT, SHOW_MORE_BUTTON_HEIGHT, SearchSectionHeader, ShowMoreButton, TRADER_ITEM_HEIGHT, TraderItemResult, } from './items';
const getResultList = ({ result, lists, showMoreState, handleToggle, handleTraderClick, handleClientClick, }) => {
    const clients = Array.from(lists.clients);
    const portfolios = Array.from(lists.portfolios);
    const traders = Array.from(lists.traders);
    const longestIndex = Math.max(result.clients.length, result.portfolios.length, result.traders.length);
    for (let i = 0; i < longestIndex; i += 1) {
        const couldAddPortfolio = (portfolios.length < 4) ||
            (portfolios.length >= 4 && showMoreState.portfolios);
        const couldAddClient = (clients.length < 4) ||
            (clients.length >= 4 && showMoreState.clients);
        const couldAddTrader = (traders.length < 4) ||
            (traders.length >= 4 && showMoreState.traders);
        if (result.portfolios[i] && couldAddPortfolio) {
            portfolios.push({
                itemKey: result.portfolios[i].id,
                children: (_createElement(PortfolioItemResult, Object.assign({}, result.portfolios[i], { key: result.portfolios[i].id }))),
                addDivider: true,
                itemHeight: PORTFOLIO_ITEM_HEIGHT,
            });
        }
        if (result.clients[i] && couldAddClient) {
            clients.push({
                itemKey: result.clients[i].id,
                children: (_createElement(ClientItemResult, Object.assign({}, result.clients[i], { key: result.clients[i].id, followersCount: result.clients[i].followersCount || 0, clientImageUrl: result.clients[i].profilePicture.thumbnail, clientName: result.clients[i].displayName }))),
                addDivider: true,
                itemHeight: CLIENT_ITEM_HEIGHT,
                onClick: () => handleClientClick(result.clients[i]),
            });
        }
        if (result.traders[i] && couldAddTrader) {
            traders.push({
                itemKey: result.traders[i].id,
                children: (_createElement(TraderItemResult, Object.assign({}, result.traders[i], { key: result.traders[i].id, creationDate: String(result.traders[i].createdAt), followers: result.traders[i].followersCount || 0, incomeAmount: 0, portfoliosCount: 0, traderImageUrl: result.traders[i].profilePicture.thumbnail, subscribedPortfoliosCount: 0, traderName: result.traders[i].displayName, verified: true }))),
                addDivider: true,
                itemHeight: TRADER_ITEM_HEIGHT,
                onClick: () => handleTraderClick(result.traders[i]),
            });
        }
    }
    const showMorePortfolios = result.portfolios.length > 3 && !showMoreState.portfolios;
    const showMoreTraders = result.traders.length > 3 && !showMoreState.traders;
    const showMoreClients = result.clients.length > 3 && !showMoreState.clients;
    const showLessPortfolios = result.portfolios.length > 3 && showMoreState.portfolios;
    const showLessTraders = result.clients.length > 3 && showMoreState.traders;
    const showLessClients = result.clients.length > 3 && showMoreState.clients;
    if (showMorePortfolios) {
        portfolios.push({
            itemKey: 'show-more-portfolios',
            children: _jsx(ShowMoreButton, { showMore: true, onClick: handleToggle('portfolios') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    else if (showLessPortfolios) {
        portfolios.push({
            itemKey: 'show-less-portfolios',
            children: _jsx(ShowMoreButton, { onClick: handleToggle('portfolios') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    if (showMoreClients) {
        clients.push({
            itemKey: 'show-more-clients',
            children: _jsx(ShowMoreButton, { showMore: true, onClick: handleToggle('clients') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    else if (showLessClients) {
        clients.push({
            itemKey: 'show-less-clients',
            children: _jsx(ShowMoreButton, { onClick: handleToggle('clients') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    if (showMoreTraders) {
        traders.push({
            itemKey: 'show-more-traders',
            children: _jsx(ShowMoreButton, { showMore: true, onClick: handleToggle('traders') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    else if (showLessTraders) {
        traders.push({
            itemKey: 'show-less-traders',
            children: _jsx(ShowMoreButton, { onClick: handleToggle('traders') }),
            itemHeight: SHOW_MORE_BUTTON_HEIGHT,
            addDivider: true,
            disableActive: true,
            disableHover: true,
        });
    }
    return {
        traders,
        clients,
        portfolios,
    };
};
const getSearchResultItems = ({ result, handleToggle, showMoreState, handleClientClick, handleTraderClick, }) => {
    const portfolios = [
        {
            itemKey: 'portfolios-header',
            disableHover: true,
            disableActive: true,
            children: (_jsx(SearchSectionHeader, { type: 'portfolio', count: result.portfolios.length })),
            itemHeight: SECTION_TITLE_HEIGHT,
        },
    ];
    const traders = [
        {
            itemKey: 'traders-header',
            disableHover: true,
            disableActive: true,
            children: _jsx(SearchSectionHeader, { type: 'trader', count: result.traders.length }),
            itemHeight: SECTION_TITLE_HEIGHT,
        },
    ];
    const clients = [
        {
            itemKey: 'clients-header',
            disableHover: true,
            disableActive: true,
            children: (_jsx(SearchSectionHeader, { type: 'client', count: result.clients.length })),
            itemHeight: SECTION_TITLE_HEIGHT,
        },
    ];
    const items = getResultList({
        result,
        lists: {
            clients,
            traders,
            portfolios,
        },
        showMoreState,
        handleToggle,
        handleClientClick,
        handleTraderClick,
    });
    return Object.assign({}, items);
};
export { getSearchResultItems, getResultList, };
