import { useState } from 'react';
import { useLazySearchItemsByNameQuery } from '@shared/api/profile';
const useTopbarSearch = () => {
    const [showMoreState, setShowMoreState] = useState({
        clients: false,
        portfolios: false,
        traders: false,
    });
    const [searchReq, { status }] = useLazySearchItemsByNameQuery();
    const handleToggle = (key) => setShowMoreState((prev) => (Object.assign(Object.assign({}, prev), { [key]: !prev[key] })));
    return {
        status: 'idle',
        handleToggle,
        showMoreState,
        result: {
            traders: [],
            portfolios: [],
            clients: [],
            // traders: TRADER_MOCKS,
            // portfolios: PORTFOLIO_MOCKS,
            // clients: CLIENT_MOCKS,
        },
    };
};
export default useTopbarSearch;
